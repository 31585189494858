import React from 'react';

const ImageGallery = ({ images, openModal }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 p-8 px-4 bg-zinc-800 flex flex-col">
      {images.map((image, index) => (
        <div onClick={() => openModal(image.projectName)} key={index} className="flex flex-col justify-center hover:cursor-pointer ">
          <div className="flex justify-center items-center relative align-middle h-full group">
            <p className='hover:bg-[rgba(0,0,0,.5)] absolute flex justify-center h-full w-full text-xl hidden group-hover:flex text-white items-center'>ADDITIONAL PHOTOS</p>
            <img src={image.backgroundImage} alt={`Image ${index}`} className="rounded object-fill" />
          </div>
          <div className='py-4 bg-zinc-300 rounded-b text-xl'>
            <p className="text-zinc-800 font-medium py-6">{image.projectName}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
